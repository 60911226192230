import request from '@/utils/request'

// 前台获取跟踪列表
export const getTrackLists = (params: any) =>
  request({
    url: '/front/orders/index',
    method: 'post',
    data: params
  })
// 前台下载妥投证明
export const donwloadDel = (params: any) =>
  request({
    url: '/front/orders/delivered-prove',
    method: 'post',
    data: params
  })
// 多查询
export const multSearch = (params: any) =>
  request({
    url: '/front/orders/delivered-prove',
    method: 'post',
    data: params
  })
// 导出
export const orderExport = (params: any) =>
  request({
    url: '/front/orders/export',
    method: 'post',
    data: params
  })
