

















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import downloadDialog from '@/components/downDialog/index.vue'
import {
  getCountry,
  getShippingLists
} from '@/api/prescription'
import { AppModule } from '@/store/modules/app'
import { donwloadDel, getTrackLists, multSearch, orderExport } from '@/api/getorder'
interface mySpan {
  row: Object
  column: Object
  rowIndex: number
  columnIndex: number
}
interface serchObj{
  page:number
  page_size:number
  ship_type:string
  orders_code:string
  send_from?:string
  send_to?:string
  is_dlv?:string
}
@Component({
  name: 'getorder',
  components: {
    Pagination,
    downloadDialog
  }
})
export default class extends Vue {
  get lang() {
    return AppModule.language.toString()
  }

  private form = {
    is_dlv: '',
    ship_type: '',
    country_id: '',
    orders_code: '',
    timearr: [],
    send_from: '',
    send_to: ''
  };

  private loading = false

  private downloadFlag = false
  private msearFlag = false
  private lot_code = ''

  private columns = [
    { id: 'orders_code', label: '订单号' },
    { id: 'ae_tracking_number', label: 'AE单号' },
    { id: 'tracking_number', label: '跟踪号' },
    { id: 'customer_order_code', label: '客户参考号' },
    { id: 'ship_type', label: '运输方式' },
    { id: 'cn_name', label: '收件人国家/地区' },
    { id: 'recipient_name', label: '收件人姓名' },
    { id: 'send_date', label: '发货时间' },
    { id: 'sfc_warehouse_process_date', label: '仓库处理中' },
    { id: 'sfc_driver_pickup', label: '揽收时间' },
    { id: 'online_date', label: '末端上网时间' },
    { id: 'delivered_date', label: '签收时间' },
    { id: 'dlv_time', label: '投递时效' },
    { id: 'last_track_date', label: '轨迹时间' },
    { id: 'last_track_content', label: '最新轨迹内容' },
    { id: 'is_dlv', label: '状态' }
  ];

  @Watch('lang', { immediate: true })
  getlang(newone:any) {
    if (newone === 'en') {
      this.columns[0].label = 'Order number'
      this.columns[1].label = 'AE single number'
      this.columns[2].label = 'Tracking number'
      this.columns[3].label = 'Customer Reference Number'
      this.columns[4].label = 'Shipping Method'
      this.columns[5].label = 'Recipient Country/Region'
      this.columns[6].label = 'Recipient Name'
      this.columns[7].label = 'Delivery time'
      this.columns[8].label = 'warehouse processing'
      this.columns[9].label = 'Collection time'
      this.columns[10].label = 'End Internet time'
      this.columns[11].label = 'Receipt time'
      this.columns[12].label = 'Delivery time limit'
      this.columns[13].label = 'Track Time'
      this.columns[14].label = 'Latest track content'
      this.columns[15].label = 'Status'
      this.columns[5].id = 'en_name'
      this.columns[15].id = 'en_is_dlv'
    } else {
      this.columns[0].label = '订单号'
      this.columns[1].label = 'AE单号'
      this.columns[2].label = '跟踪号'
      this.columns[3].label = '客户参考号'
      this.columns[4].label = '运输方式'
      this.columns[5].label = '收件人国家/地区'
      this.columns[6].label = '收件人姓名'
      this.columns[7].label = '发货时间'
      this.columns[8].label = '仓库处理中'
      this.columns[9].label = '揽收时间'
      this.columns[10].label = '末端上网时间'
      this.columns[11].label = '签收时间'
      this.columns[12].label = '投递时效'
      this.columns[13].label = '轨迹时间'
      this.columns[14].label = '最新轨迹内容'
      this.columns[15].label = '状态'
      this.columns[5].id = 'cn_name'
      this.columns[15].id = 'is_dlv'
    }
  }

  public countryList = [];
  public shippingList = [];
  public ids = [];
  public msg = ''

  private pagObj = {
    total: 0,
    limit: 10,
    page: 1
  };

  private tdata = []

  created() {
    this.getShippingLists()
    this.getCountry()
    this.getTrackLists()
  }

  public getShippingLists() {
    const obj = {}
    getShippingLists(obj).then((res) => {
      this.shippingList = res.data
    })
  }

  public getCountry() {
    const obj = {}
    getCountry(obj).then((res) => {
      this.countryList = res.data
    })
  }

  public selectable(row:any) {
    if (row.is_dlv !== '未妥投') {
      return true
    }
    return false
  }

  public multSearch() {
    const obj = {}
    multSearch(obj).then((res) => {
      console.log(res)
      this.closemsearFlag()
    })
  }

  public closemsearFlag() {
    this.lot_code = ''
    this.msearFlag = false
  }

  private arraySpanMethod = function(obj: mySpan) {
    if (obj.columnIndex === 0) {
      if ([1, 2, 3].includes(obj.rowIndex)) {
        return [0, 0]
      }
      return [4, 1]
    }
  };

  pagination(val: any) {
    console.log(val)
    this.$set(this.pagObj, 'page', val.page)
    this.$set(this.pagObj, 'limit', val.limit)
    this.$set(this.pagObj, 'total', 0)
    this.getTrackLists()
  }

  clearall() {
    const dom:any = this.$refs.form
    dom.resetFields()
    this.pagObj.page = 1
    this.getTrackLists()
  }

  getTrackLists() {
    this.loading = true
    const obj:serchObj = Object.assign({},
      {
        page: this.pagObj.page,
        page_size: this.pagObj.limit,
        ship_type: this.form.ship_type,
        country_id: this.form.country_id,
        orders_code: this.form.orders_code,
        is_dlv: this.form.is_dlv,
        lot_code: this.lot_code.split('\n').filter(i => i)
      })
    if (this.form.timearr && this.form.timearr.length > 0) {
      obj.send_from = this.form.timearr[0]
      obj.send_to = this.form.timearr[1]
    }
    getTrackLists(obj).then((res) => {
      this.loading = false
      this.tdata = res.data.list
      this.pagObj.total = +res.data.total
    })
  }

  searchRes() {
    this.msearFlag = false
    this.pagObj.page = 1
    this.getTrackLists()
  }

  closeDialog() {
    this.downloadFlag = false
    this.getTrackLists()
  }

  getChange(val:any) {
    this.ids = val.map((item: { orders_id: any }) => {
      return item.orders_id
    })
  }

  exportExcle() {
    const obj = {
      orders_code: this.form.orders_code,
      orders_ids: this.ids
    }
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading'
    })
    donwloadDel(obj).then(res => {
      loading.close()
      if (res.code === '000000') {
        if (res.data.download_url === '') {
          this.downloadFlag = true
          this.msg = res.msg
        } else {
          // window.open(res.data.download_url, '_blank')
          const link = document.createElement('a')
          link.href = res.data.download_url
          link.click()
        }
        this.getTrackLists()
      }
    }).catch(e => {
      loading.close()
    })
  }

  exportData() {
    const obj = Object.assign({},
      {
        ship_type: this.form.ship_type,
        country_id: this.form.country_id,
        orders_code: this.form.orders_code,
        is_dlv: this.form.is_dlv,
        send_from: '',
        send_to: '',
        lot_code: this.lot_code.split('\n').filter(i => i)
      })
    if (this.form.timearr && this.form.timearr.length > 0) {
      obj.send_from = this.form.timearr[0]
      obj.send_to = this.form.timearr[1]
    }
    orderExport(obj).then(res => {
      if (res.code === '000000') {
        if (res.data && res.data.download_url) {
          const link = document.createElement('a')
          link.href = res.data.download_url
          link.click()
          this.$message.success('导出成功')
          this.msearFlag = false
          this.lot_code = ''
        }
      }
    })
  }
}
