




















import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  name: 'downloadDialog'
})
export default class extends Vue {
    @Prop()
    downloadFlag=false

    @Prop()
    msg=''

    get downloadFlag1() {
      return this.downloadFlag
    }

    jumpDownload() {
      this.$router.push('/download')
    }

    closeDialog() {
      const dom:any = this.$parent
      dom.closeDialog()
    }
}
